import { styled, keyframes } from '@mui/system';

import variableStyles from 'assets/styles/variable-styles';

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

export const Circle = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  -ms-transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${variableStyles.primaryColor};
    border-radius: 100%;
    animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
    -webkit-animation-delay: ${({ delay }) => `${delay}s`};
    animation-delay: ${({ delay }) => `${delay}s`};
  }
`;

export const LoadingIndicatorWrapper = styled('div')`
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;
