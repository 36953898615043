import { makeStyles } from '@mui/styles';
import variableStyles from 'assets/styles/variable-styles';
import { rgba, pxToRem } from 'utils/theme';

export const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(to right, #e52d27 0%, #b31217  51%, #e52d27  100%)',
    border: pxToRem(0),
    borderRadius: pxToRem(12),
    boxShadow: '0 0 20px #eee',
    color: rgba(variableStyles.textWhite),
    height: pxToRem(48),
    padding: '0 30px',
    margin: pxToRem(8),
    '&:hover': {
      backgroundPosition: 'right center',
      textDecoration: 'none',
    },
  },
});
