import React, { lazy, Suspense } from 'react';
import { LoadingIndicator } from 'components';

const LazyComponent = lazy(() => import('./index'));

export default function MasterLayoutLoadabel() {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <LazyComponent />
    </Suspense>
  );
}
