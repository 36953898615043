import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import variableStyles from './variable-styles';

export const globalStyles = {
  body: {
    fontFamily: 'Inter',
    color: variableStyles.primaryInk,
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: variableStyles.fontSize_14,
    lineHeight: variableStyles.lineHeight_20,
    letterSpacing: variableStyles.letter_spacing_3,
    margin: 0,
  },
  h1: {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwStrongBold,
    fontSize: variableStyles.fontSize_46,
    lineHeight: variableStyles.lineHeight_56,
    letterSpacing: variableStyles.letter_spacing_4,
  },
  h2: {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwStrongBold,
    fontSize: variableStyles.fontSize_32,
    lineHeight: variableStyles.lineHeight_36,
    letterSpacing: variableStyles.letter_spacing_4,
  },
  h3: {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwStrongBold,
    fontSize: variableStyles.fontSize_22,
    lineHeight: variableStyles.lineHeight_28,
    letterSpacing: variableStyles.letter_spacing_3,
  },
  h4: {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwStrongBold,
    fontSize: variableStyles.fontSize_18,
    lineHeight: variableStyles.lineHeight_24,
  },
  '&.title-1': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwStrongBold,
    fontSize: variableStyles.fontSize_16,
    lineHeight: variableStyles.lineHeight_22,
  },
  '&.title-2': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwBold,
    fontSize: variableStyles.fontSize_16,
    lineHeight: variableStyles.lineHeight_22,
  },
  '&.subtitle': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwBold,
    fontSize: variableStyles.fontSize_14,
    lineHeight: variableStyles.lineHeight_20,
    letterSpacing: variableStyles.letterSpacing_1,
  },
  '&.caption-1': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwBold,
    fontSize: variableStyles.fontSize_12,
    lineHeight: variableStyles.lineHeight_16,
  },
  '&.caption-2': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: variableStyles.fontSize_12,
    lineHeight: variableStyles.lineHeight_16,
  },
  '&.label': {
    fontFamily: 'Inter',
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwBold,
    fontSize: variableStyles.fontSize_10,
    lineHeight: variableStyles.lineHeight_14,
  },
  '&.fwBold': {
    fontWeight: variableStyles.fwStrongBold,
  },
  '&.text-center': {
    textAlign: 'center',
  },
  '&.text-right': {
    textAlign: 'right',
  },
  '&.text-left': {
    textAlign: 'left',
  },
  '&.flex-center': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&.flex-start-align-center': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '&.flex-start-align-top': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  '&.flex-start-align-bottom': {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  '&.flex-between-align-center': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

function AppGlobalStyles() {
  // Note: It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering.
  // This will ensure that the <style> tag generated would not recalculate on each render.

  const baseStyles = <GlobalStyles styles={globalStyles} />;

  return baseStyles;
}

export default AppGlobalStyles;
