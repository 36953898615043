import React, { cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'mui-image';
import { useCycle, motion } from 'framer-motion';
import { use100vh } from 'react-div-100vh';
// constants
import images from 'constants/images';
// routes
import headerRoutes from 'routes/headerRoutes';
// components
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  useScrollTrigger,
  useMediaQuery,
} from '@mui/material';
import Menu from './Menu';
import MenuToggle from './MenuToggle';
// styles
import { Logo, useStyles } from './style';

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function ResponsiveHeader(props) {
  const classes = useStyles();
  const height = use100vh();
  const navRef = useRef(null);
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  // states
  const [isOpen, toggleOpen] = useCycle(false, true);

  const onToggleMenuHeader = () => {
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
    toggleOpen();
  };

  const onChangePage = (e, value) => {
    e.preventDefault();
    smoothScroll(value);
    if (matches) {
      onToggleMenuHeader();
    }
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollGo = (element, to, duration) => {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const smoothScroll = (target) => {
    const targetScroll = document.getElementById(target);
    if (targetScroll) {
      scrollGo(document.documentElement, targetScroll.offsetTop - 100, 800);
    }
  };

  const sidebar = {
    open: {
      clipPath: `circle(${height * 2 + 200}px at 60px 60px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    },
    closed: {
      rotateY: 180,
      clipPath: 'circle(0px at 0px 0px)',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const animateNav = isOpen ? 'open' : 'closed';

  return (
    <div className={classes.headerWrapper}>
      <ElevationScroll {...props}>
        <AppBar className={classes.appBar}>
          {matches ? (
            <motion.nav
              className={classes.navbar}
              ref={navRef}
              initial={false}
              animate={animateNav}
            >
              <motion.div
                variants={sidebar}
                className={classes.sidebar}
                style={{
                  height,
                }}
              />
              <Logo onClick={(e) => onChangePage(e, 'home')}>
                <Image
                  src={images.LOGO}
                  duration={0}
                  alt="LOGO YEN SAO HAO PHONG"
                />
              </Logo>
              <MenuToggle toggle={onToggleMenuHeader} />
              <Menu onChangePage={onChangePage} />
            </motion.nav>
          ) : (
            <Container className={classes.container}>
              <Toolbar disableGutters>
                <Logo onClick={(e) => onChangePage(e, 'home')}>
                  <Image
                    src={images.LOGO}
                    duration={0}
                    alt="LOGO YEN SAO HAO PHONG"
                  />
                </Logo>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {headerRoutes.private.map((menu) => (
                    <Button
                      key={menu.key}
                      sx={{
                        my: 2,
                        color: 'white',
                        fontWeight: 600,
                        display: 'block',
                      }}
                      onClick={(e) => {
                        const isMobile = navigator.userAgent.match(
                          /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
                        );
                        if (isMobile) {
                          // if we are on mobile device the scroll into view will be managed by the browser
                        } else {
                          onChangePage(e, menu.key);
                        }
                      }}
                    >
                      {menu.name}
                    </Button>
                  ))}
                </Box>
              </Toolbar>
            </Container>
          )}
        </AppBar>
      </ElevationScroll>

      <Toolbar className={classes.toolbarAnchor} id="back-to-top-anchor" />
    </div>
  );
}
export default ResponsiveHeader;
