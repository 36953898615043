import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import variableStyles from 'assets/styles/variable-styles';

export const MenuRoot = styled(motion.ul)`
  padding: 24px 16px;
  position: absolute;
  top: 96px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 8;
`;

export const MenuItem = styled(motion.li)`
  text-align: center;
  list-style: none;
  margin-bottom: 32px;
  cursor: pointer;
  h3 {
    color: ${variableStyles.textWhite};
    &.active {
      color: ${variableStyles.secondaryColor};
    }
  }
`;
