import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// routes
import headerRoutes from 'routes/headerRoutes';
// styles
import { MenuItem, MenuRoot } from './style';

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const variantsMenu = {
  open: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { staggerChildren: 0.07, delayChildren: 0.1 },
    transitionEnd: { display: 'block' },
  },
  closed: {
    exit: { opacity: 0 },
    transition: { staggerChildren: 0.07, staggerDirection: -1 },
    transitionEnd: { display: 'none' },
  },
};

function Menu({ onChangePage }) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <MenuRoot variants={variantsMenu}>
        {headerRoutes.private.map((menu) => (
          <MenuItem
            key={menu.key}
            variants={variantsItem}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 1 }}
            onClick={(e) => onChangePage(e, menu.key)}
          >
            <h3 className={classNames({ active: menu.path === pathname })}>
              {menu.name}
            </h3>
          </MenuItem>
        ))}
      </MenuRoot>
    </AnimatePresence>
  );
}

Menu.propTypes = {
  onChangePage: PropTypes.func,
};

export default Menu;
