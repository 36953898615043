import { makeStyles } from '@mui/styles';
import variableStyles from 'assets/styles/variable-styles';
import images from 'constants/images';

export const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    backgroundImage: `url(${images.BG_1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  blockMenu: {
    '& h4': {
      margin: '16px 0',
    },
    '& a': {
      color: variableStyles.primaryInk,
    },
  },
  subMenu: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& .sub-menu__item': {
      margin: '0 0 8px',
      '& a': {
        position: 'relative',
        display: 'inline-block',
        paddingBottom: '6px',
        color: variableStyles.primaryInk,
        textDecoration: 'none',
        '&::after': {
          content: '""', // "''" will also work.
          opacity: 1,
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '1px',
          width: '100%',
          transform: 'scaleX(0)',
          transitionDuration: '.3s',
          backgroundColor: variableStyles.primaryInk,
        },
      },

      '&:hover': {
        '& a': {
          '&::after': {
            transform: 'scaleX(1)',
            transitionDuration: '.3s',
          },
        },
      },
    },
  },
}));
