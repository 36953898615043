import IMG_FOR_CHILDREN from 'assets/images/children.jpeg';
import IMG_FOR_WOMAN_PREGNANT from 'assets/images/woman-pregnant.jpeg';
import IMG_FOR_WOMAN_AFTER_BIRTH from 'assets/images/woman-after-birth.jpeg';
import IMG_FOR_OLD_PEOPLE from 'assets/images/old-people.jpeg';
import IMG_FOR_MAN from 'assets/images/man.jpeg';
import BG_1 from 'assets/images/bg-7.jpeg';
// icons
import ICON_NUMBER_1 from 'assets/images/icons/001-1.png';
import ICON_NUMBER_2 from 'assets/images/icons/002-2.png';
import ICON_NUMBER_3 from 'assets/images/icons/003-3.png';
import ICON_NUMBER_4 from 'assets/images/icons/001-4.png';
import ICON_NUMBER_5 from 'assets/images/icons/002-5.png';
import ICON_NUMBER_6 from 'assets/images/icons/003-6.png';
import ICON_BIRDS from 'assets/images/icons/001-birds.png';
// logo
import LOGO from 'assets/images/logo-yen-sao-hao-phong.png';
// banners
import BANNER_HOME from 'assets/images/banners/banner-home.jpg';
import BANNER_HOME_1 from 'assets/images/banners/yen-sao-danh-cho-ba-bau.jpg';
import BANNER_HOME_SALE from 'assets/images/banners/banner-sale.jpg';
import BANNER_HOME_GIFT from 'assets/images/banners/banner-hop-qua-tang.jpg';
import BANNER_HOME_2 from 'assets/images/banners/banner_noel_optimized_1.jpg';
import BANNER_HOME_3 from 'assets/images/banners/banner_optimized_2.jpg';
// products
import IMG_PRODUCT_EXAMPLE from 'assets/images/products/yen-chung-duong-phen-tao-do.jpg';
import IMG_PRODUCT_TINH_CHE_VUN from 'assets/images/products/yen-tinh-che-vun.jpeg';
import IMG_PRODUCT_TINH_CHE_VUN_100GRAM from 'assets/images/products/yen-tinh-che-vun-100gram.jpg';
import IMG_PRODUCT_TINH_CHE_VUN_DANG_VIEN from 'assets/images/products/yen-tinh-che-vun-dang-vien.jpg';
import IMG_PRODUCT_TINH_CHE_SOI from 'assets/images/products/yen-tinh-che-soi-100gram.jpg';
import IMG_PRODUCT_YEN_CHUNG_DUONG_PHEN from 'assets/images/products/yen-chung-duong-phen.jpg';
import IMG_PRODUCT_YEN_CHUNG_DONG_TRUNG from 'assets/images/products/yen-chung-dong-trung.jpg';
import IMG_PRODUCT_YEN_CHUNG_KHONG_DUONG from 'assets/images/products/yen-chung-khong-duong.jpg';
import IMG_PRODUCT_YEN_THO_LOAI_1 from 'assets/images/products/to-yen-tho.jpeg';
import IMG_PRODUCT_YEN_THO_LOAI_2 from 'assets/images/products/to-yen-tho-loai-thuong.jpeg';
import IMG_PRODUCT_CHAN_YEN_RUT_LONG from 'assets/images/products/chan-yen-rut-long-100-gr.jpeg';
import IMG_PRODUCT_HOP_QUA_TANG from 'assets/images/products/hop-qua-tang.jpg';

const images = {
  LOGO,
  // BANNERS
  BANNER_HOME,
  BANNER_HOME_1,
  BANNER_HOME_2,
  BANNER_HOME_3,
  BANNER_HOME_SALE,
  BANNER_HOME_GIFT,
  // PRODUCTs
  IMG_PRODUCT_EXAMPLE,
  IMG_PRODUCT_TINH_CHE_VUN,
  IMG_PRODUCT_TINH_CHE_VUN_100GRAM,
  IMG_PRODUCT_TINH_CHE_VUN_DANG_VIEN,
  IMG_PRODUCT_TINH_CHE_SOI,
  IMG_PRODUCT_YEN_CHUNG_DUONG_PHEN,
  IMG_PRODUCT_YEN_CHUNG_DONG_TRUNG,
  IMG_PRODUCT_YEN_CHUNG_KHONG_DUONG,
  IMG_PRODUCT_YEN_THO_LOAI_1,
  IMG_PRODUCT_YEN_THO_LOAI_2,
  IMG_PRODUCT_CHAN_YEN_RUT_LONG,
  IMG_PRODUCT_HOP_QUA_TANG,
  // IMAGES
  IMG_FOR_CHILDREN,
  IMG_FOR_WOMAN_PREGNANT,
  IMG_FOR_WOMAN_AFTER_BIRTH,
  IMG_FOR_MAN,
  IMG_FOR_OLD_PEOPLE,
  ICON_NUMBER_1,
  ICON_NUMBER_2,
  ICON_NUMBER_3,
  ICON_NUMBER_4,
  ICON_NUMBER_5,
  ICON_NUMBER_6,
  ICON_BIRDS,
  BG_1,
};
export default images;
