import { createStore } from './store';

export const AppStore = createStore({
  // initial state
  isLogin: true,
  isWaiting: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  errorCode: '',
  currentUser: {},
  notify: {
    title: '',
    description: '',
    status: '',
    duration: 0,
    method: '',
  },
});

export const useAppStore = AppStore.useStore;
