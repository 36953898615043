import LoginLayoutLoadabel from 'layouts/Login/loadable';
import MasterLayoutLoadabel from 'layouts/Master/loadable';

const appRoutes = {
  private: [
    {
      viewId: 'privateRoutes',
      path: '/*',
      element: MasterLayoutLoadabel,
    },
  ],
  public: [
    {
      viewId: 'publicRoutes',
      path: '/',
      element: LoginLayoutLoadabel,
    },
    {
      viewId: 'notFound',
      path: '*',
      element: LoginLayoutLoadabel,
    },
  ],
};

export default appRoutes;
