const variableStyles = {
  primaryColor: '#AA0000',
  secondaryColor: '#FBB040',
  // Primary Color - Button, featured elements
  primaryColor100: '#F5F9FF',
  primaryColor200: '#D7E8FE',
  primaryColor300: '#B9D7FE',
  primaryColor400: '#7DB5FC',
  primaryColor500: '#4192FB',
  primaryColor600: '#0571FA',
  colorBlueDark: '#0077CC',
  // Text Color
  primaryInk: '#1C1C28',
  primaryWhite: '#FFFFFF',
  // Accent - Decorate, elements priority lower
  accentLime100: '#FCFFE6',
  accentLime200: '#EFF9D3',
  accentLime300: '#E5F5B8',
  accentLime400: '#D0EE83',
  accentLime500: '#BCE64D',
  accentLime600: '#A5D81E',

  accentCyan100: '#F0FAFF',
  accentCyan200: '#CDEFFE',
  accentCyan300: '#B9E8FE',
  accentCyan400: '#7DD4FC',
  accentCyan500: '#41C1FB',
  accentCyan600: '#05ADFA',
  // Neutral Color - Text, background, border, surface,...
  neutral100: '#F5F7FA',
  neutral200: '#E4E7EB',
  neutral300: '#BDC4CC',
  neutral400: '#9AA5B1',
  neutral500: '#616E7C',
  neutral600: '#323F4B',
  neutralScrim: 'rgba(50, 63, 75, 0.64)',
  // Semantic - Notice feedback
  semanticError100: '#FDF7F7',
  semanticError200: '#F9E2E1',
  semanticError300: '#F4C6C3',
  semanticError400: '#EA948F',
  semanticError500: '#E1655D',
  semanticError600: '#DC4B41',

  semanticSuccess100: '#F4FBF6',
  semanticSuccess200: '#E4F6EA',
  semanticSuccess300: '#CAEDD5',
  semanticSuccess400: '#A4E0B8',
  semanticSuccess500: '#7ED39A',
  semanticSuccess600: '#58C67C',

  semanticWarning100: '#FEFAF1',
  semanticWarning200: '#FDF3DD',
  semanticWarning300: '#FBE6BB',
  semanticWarning400: '#F9D48B',
  semanticWarning500: '#F6C25A',
  semanticWarning600: '#F4B02A',
  // Secondary
  secondaryInk: '#555970',
  // text
  textTertiary: '#9CA2B1',
  textWhite: '#FFFFFF',
  textGray: '#9CA2B1',
  inkSecondary: '#555970',
  textBlueBasic: '#33AAFF',
  textGreen: '#96E000',
  innerBoderGray: '#E4E7EB',
  textError: '#FF5500',
  // + Background color:
  backgroundWhite: '#FFFFFF',
  backgroundDark: '#000000',
  backgroundGray: '#F6F6F9',
  processBarColor: '#C9DD03',
  // Gradient color
  gradientDecorateCyan: 'linear-gradient(180deg, #F0FAFF 0%, #7DD4FC 100%)',
  gradientCyan: 'linear-gradient(180deg, #37BDFB 0%, #05ADFA 100%)',
  gradientBlue: 'linear-gradient(180deg, #378DFB 0%, #0571FA 100%)',

  // Effect Box-shadow - Separate surface and background
  boxShadowDeep:
    '0px 0px 2px rgba(42, 50, 60, 0.08), 0px 8px 12px rgba(42, 50, 60, 0.04), 0px 8px 24px rgba(42, 50, 60, 0.04)',
  boxShadowRim: '0px 1px 8px rgba(42, 50, 60, 0.08)',
  boxShadowSoft:
    '0px 2px 4px rgba(42, 50, 60, 0.02), 0px 8px 16px rgba(42, 50, 60, 0.04)',

  // Font Size
  fontSize_10: '10px',
  fontSize_12: '12px',
  fontSize_13: '13px',
  fontSize_14: '14px',
  fontSize_16: '16px',
  fontSize_18: '18px',
  fontSize_20: '20px',
  fontSize_22: '22px',
  fontSize_24: '24px',
  fontSize_28: '28px',
  fontSize_32: '32px',
  fontSize_36: '36px',
  fontSize_40: '40px',
  fontSize_46: '46px',
  fontSize_48: '48px',
  fontSize_56: '56px',
  fontSize_64: '64px',
  fontSize_72: '72px',
  fontSize_96: '96px',
  fsSubtitle_1: '12px',
  fsSubtitle_2: '13px',
  fsBodyText_1: '18px',

  // Font Style
  fsNormal: 'normal',

  // Font Weight
  fwNormal: 400,
  fwLight: 300,
  fwSemiBold: 500,
  fwBold: 600,
  fwExtraBold: 800,
  fwStrongBold: 'bold',

  // Line height
  lineHeight_0: 'normal',
  lineHeight_14: '14px',
  lineHeight_16: '16px',
  lineHeight_18: '18px',
  lineHeight_19: '19px',
  lineHeight_20: '20px',
  lineHeight_22: '22px',
  lineHeight_24: '24px',
  lineHeight_25: '25px',
  lineHeight_26: '26px',
  lineHeight_28: '28px',
  lineHeight_30: '30px',
  lineHeight_32: '32px',
  lineHeight_33: '33px',
  lineHeight_36: '36px',
  lineHeight_40: '40px',
  lineHeight_48: '48px',
  lineHeight_54: '54px',
  lineHeight_56: '56px',
  lineHeight_62: '62px',
  lineHeight_64: '64px',
  lineHeight_72: '72px',

  // Letter spacing
  letterSpacing_1: '0.1px',
  letterSpacing_2: '0.14px',
  letter_spacing_3: '-0.1px',
  letter_spacing_4: '-0.2px',
};

export default variableStyles;
