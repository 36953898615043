import { makeStyles } from '@mui/styles';
import variableStyles from 'assets/styles/variable-styles';

export const useStyles = makeStyles((theme) => ({
  carousel: {
    '& .slick-dots': {
      marginBottom: 0,
      '& li': {
        width: 'fit-content',
        '& .custom-dot': {
          width: '12px',
          height: '12px',
          background: '#FFFFFF',
          borderRadius: '20px',
          opacity: 0.6,
        },
      },
      '& .slick-active': {
        '& .custom-dot': {
          width: '40px',
          height: '12px',
          background: '#FFFFFF',
          borderRadius: '20px',
          opacity: 1,
        },
      },
    },

    '& .slick-arrow': {
      '&::before': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '56px',
        height: '56px',
        margin: '0 auto',
        color: 'rgba(170, 0, 0, 1)',
        borderRadius: '28px',
        background: 'rgba(255, 255, 255, 0.88)',
        boxShadow: '0px 8px 24px rgba(136, 151, 175, 0.08)',
        transform: 'scale(1)',
        transition: 'ease-out 0.2s',
        zIndex: 1,
      },
      '&:hover': {
        '&::before': {
          transform: 'scale(1.2)',
        },
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
  },
}));
