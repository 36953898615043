import chroma from 'chroma-js';

function hexToRgb(color) {
  return chroma(color).rgb().join(', ');
}

function rgba(color, opacity = 1) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export { pxToRem, rgba };
