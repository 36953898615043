import React from 'react';
// react-router-dom components
import { Link } from 'react-router-dom';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';
// @mui material components
import { Box, Stack, Container, Grid, Typography } from '@mui/material';
// styles
import { useStyles } from './style';

const date = new Date().getFullYear();

const contentFooter = {
  menus: [
    {
      key: 'CONTACT',
      name: 'Thông tin liên hệ',
      items: [
        {
          name: (
            <div>
              <span className="fwBold">Mở cửa: </span>
              <span>8:00 AM - 19:00 PM ( tất cả ngày trong tuần )</span>
            </div>
          ),
          href: '/',
        },
        {
          name: (
            <div>
              <span className="fwBold">Email: </span>
              <a href="mailto:yensaohaophong@gmail.com">
                yensaohaophong@gmail.com
              </a>
            </div>
          ),
          href: '/',
        },
        {
          name: (
            <div>
              <span className="fwBold">Điện thoại: </span>
              <a href="tel:0377111352">0377 111 352</a> -{' '}
              <a href="tel:0386046002">0386 046 002</a>
            </div>
          ),
          href: '/',
        },
        {
          name: (
            <div>
              <span className="fwBold">Địa chỉ: </span>
              <span>67 Cây Keo, P. Hiệp Tân, Quận Tân Phú, TP.HCM</span>
            </div>
          ),
          href: '/',
        },
      ],
    },
    {
      key: 'SUPPORT',
      name: 'Hỗ trợ',
      items: [
        {
          name: 'Tuyển CTV',
          href: '/',
        },
        {
          name: 'Đổi trả hàng',
          href: '/',
        },
        {
          name: 'Hình thức thanh toán',
          href: '/',
        },
        {
          name: 'Chính sách vận chuyển',
          href: '/',
        },
      ],
    },
    {
      key: 'NEWS',
      name: 'Tìm hiểu thêm',
      items: [
        {
          name: 'Mua yến ở đâu chất lượng?',
          href: '/',
        },
        {
          name: 'Tác dụng của yến sào',
          href: '/',
        },
        {
          name: 'Hướng dẫn chưng yến',
          href: '/',
        },
        {
          name: 'Sự kiện khuyến mãi',
          href: '/',
        },
      ],
    },
  ],
  copyright: (
    <Typography variant="button" fontWeight="regular">
      &copy; {date} Bản quyền thuộc về Yến sào Hảo Phong
    </Typography>
  ),
};

function Footer() {
  const classes = useStyles();
  const { menus, copyright } = contentFooter;

  return (
    <Box
      component="footer"
      className={classes.footerWrapper}
      id="contact-section"
    >
      <Container
        sx={{
          padding: '24px',
        }}
      >
        <Grid container spacing={3}>
          {menus.map((menu) => {
            if (menu.key === 'CONTACT') {
              return (
                <Grid
                  key={menu.key}
                  item
                  md={4}
                  xs={12}
                  className={classes.blockMenu}
                >
                  <h4>{menu.name}</h4>
                  <Stack spacing={1}>
                    {menu.items.map((item, index) => (
                      <div key={index} style={{ paddingBottom: 6 }}>
                        {item.name}
                      </div>
                    ))}
                  </Stack>
                </Grid>
              );
            }
            return (
              <Grid
                key={menu.key}
                item
                md={2}
                xs={6}
                className={classes.blockMenu}
              >
                <h4>{menu.name}</h4>
                <Stack spacing={1} component="ul" className={classes.subMenu}>
                  {menu.items.map((item, index) => (
                    <li key={index} className="sub-menu__item">
                      <Link to="/">{item.name}</Link>
                    </li>
                  ))}
                </Stack>
              </Grid>
            );
          })}
          <Grid item md={4} xs={12} className={classes.blockMenu}>
            <h4>Kết nối</h4>
            <iframe
              title="Yến sào Hảo Phong"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.520416928154!2d106.62708497617035!3d10.771395959284247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f04a1d40755%3A0x355f0cc9a00c270e!2zWeG6v24gU8OgbyBI4bqjbyBQaG9uZw!5e0!3m2!1sen!2s!4v1688924211431!5m2!1sen!2s"
              width="100%"
              height="200"
              style={{
                border: 0,
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {};

export default Footer;
