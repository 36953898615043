/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// styles
import { useStyles } from './style';

function CarouselComponent(props) {
  // props
  const { children, settings } = props;

  const classes = useStyles();

  const settingsCarousel = {
    dots: true,
    fade: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    customPaging: (i) => <div className="custom-dot" key={i} />,
    cssEase: 'linear',
    ...settings,
  };

  return (
    <Carousel {...settingsCarousel} className={classes.carousel}>
      {children}
    </Carousel>
  );
}
CarouselComponent.propTypes = {
  children: PropTypes.any,
  settings: PropTypes.object,
};

export default CarouselComponent;
