import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
// styles
import { useStyles } from './style';

function BlockTitle(props) {
  const classes = useStyles();
  // props
  const { children } = props;
  return (
    <div className={classes.blockTitle}>
      <Typography component="span" className={classes.title}>
        {children}
      </Typography>
    </div>
  );
}

BlockTitle.propTypes = {
  children: PropTypes.any,
};

export default BlockTitle;
