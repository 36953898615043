import images from './images';
import path from './path';

export const products = [
  {
    id: 1,
    name: 'Yến chưng đường phèn',
    categoryId: 3,
    price: 60000,
    description: '',
    quantitySold: 1600,
    discount: null,
    weight: null,
    wholesale: false,
    image: images.IMG_PRODUCT_YEN_CHUNG_DUONG_PHEN,
  },
  {
    id: 2,
    name: 'Yến chưng đông trùng',
    categoryId: 3,
    price: 70000,
    description: '',
    quantitySold: 1200,
    discount: null,
    weight: null,
    image: images.IMG_PRODUCT_YEN_CHUNG_DONG_TRUNG,
  },
  {
    id: 3,
    name: 'Yến chưng không đường',
    categoryId: 3,
    price: 60000,
    description: '',
    quantitySold: 1600,
    discount: null,
    weight: null,
    image: images.IMG_PRODUCT_YEN_CHUNG_KHONG_DUONG,
  },
  {
    id: 4,
    name: 'Yến tinh chế vụn',
    categoryId: 1,
    price: 2800000,
    description:
      'Yến vụn là những phần bị vỡ hoặc gãy, không còn nguyên vẹn, lúc này những sợi yến không dài như chúng ta thường thấy mà là sợi yến ngắn. Thành phần dưỡng chất trong yến vụn vẫn y vậy không hề khác Yến thô hay Yến tinh chế. Yến vụn chứa hơn 30 vi chất cần thiết cho cơ thể cùng 18 loại axit amin có khả năng tăng cường sức khỏe, làm đẹp dáng và da, giúp cơ xương dẻo dai, tốt cho bệnh nhân mắc bệnh tiểu đường, cao huyết áp,…',
    quantitySold: 479,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_VUN_100GRAM,
  },
  {
    id: 5,
    name: 'Yến tinh chế sợi',
    categoryId: 1,
    price: 3200000,
    description: '',
    quantitySold: 389,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_SOI,
  },
  {
    id: 6,
    name: 'Tổ yến rút lông',
    categoryId: 1,
    price: 3600000,
    description: '',
    quantitySold: 560,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_SOI,
  },
  {
    id: 7,
    name: 'Chân yến rút lông',
    categoryId: 1,
    price: 3200000,
    description:
      'Sản phẩm Chân yến tinh chế là phần rìa 2 bên của tổ chim yến được làm sạch 100% sau khi thu hoạch. Chân yến là sản phẩm có thời gian hình thành lâu hơn tổ yến, cho sợi yến dai và thơm ngon hơn.',
    quantitySold: 560,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_CHAN_YEN_RUT_LONG,
  },
  {
    id: 8,
    name: 'Tinh chế vụn dạng viên',
    categoryId: 1,
    price: 2600000,
    description: '',
    quantitySold: 260,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_VUN_DANG_VIEN,
  },
  {
    id: 9,
    name: 'Yến thô đẹp ít lông',
    categoryId: 2,
    price: 2500000,
    description: '',
    quantitySold: 242,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_YEN_THO_LOAI_1,
  },
  {
    id: 10,
    name: 'Yến thô loại thường',
    categoryId: 2,
    price: 2400000,
    description: '',
    quantitySold: 242,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_YEN_THO_LOAI_2,
  },
  {
    id: 11,
    name: 'Yến tinh chế sợi',
    categoryId: 4,
    price: 3350000,
    description: '',
    quantitySold: 242,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_HOP_QUA_TANG,
  },
];

export const productsFeatured = [
  {
    id: 1,
    name: 'Yến chưng đường phèn',
    categoryId: 3,
    price: 60000,
    description: '',
    quantitySold: 1600,
    discount: null,
    weight: null,
    image: images.IMG_PRODUCT_YEN_CHUNG_DUONG_PHEN,
  },
  {
    id: 2,
    name: 'Yến chưng đông trùng',
    categoryId: 3,
    price: 70000,
    description: '',
    quantitySold: 1200,
    discount: null,
    weight: null,
    image: images.IMG_PRODUCT_YEN_CHUNG_DONG_TRUNG,
  },
  {
    id: 3,
    name: 'Yến tinh chế vụn',
    categoryId: 1,
    price: 2700000,
    description: '',
    quantitySold: 479,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_VUN_100GRAM,
  },
  {
    id: 4,
    name: 'Yến tinh chế sợi',
    categoryId: 1,
    price: 3000000,
    description: '',
    quantitySold: 389,
    discount: null,
    weight: 100,
    image: images.IMG_PRODUCT_TINH_CHE_SOI,
  },
];

export const Promotions = [
  {
    id: 1,
    name: 'Mua 10 tặng 1',
    description:
      'Chương trình "Sức khoẻ vàng trao ngàn yêu thương" nhằm gửi gắm lời chúc sức khoẻ của "Yến Sào Hảo Phong" đến với khách hàng sau đợt dịch thứ 4 này. Áp dụng cho tất cả khách hàng trên toàn quốc!',
    createDate: '',
    startDate: '',
    endDate: '',
  },
  {
    id: 2,
    name: 'Mua 10 tặng 1',
    description:
      'Chương trình "Sức khoẻ vàng trao ngàn yêu thương" nhằm gửi gắm lời chúc sức khoẻ của "Yến Sào Hảo Phong" đến với khách hàng sau đợt dịch thứ 4 này. Áp dụng cho tất cả khách hàng trên toàn quốc!',
    createDate: '',
    startDate: '',
    endDate: '',
  },
];

export const categoryList = [
  {
    id: 1,
    name: 'Tổ yến sạch',
    image: images.IMG_PRODUCT_TINH_CHE_VUN,
    link: `${path.PRODUCTS}?categoryID=1`,
  },
  {
    id: 2,
    name: 'Tổ yến thô',
    image: images.IMG_PRODUCT_YEN_THO_LOAI_1,
    link: `${path.PRODUCTS}?categoryID=2`,
  },
  {
    id: 3,
    name: 'Yến chưng sẵn',
    image: images.IMG_PRODUCT_YEN_CHUNG_DUONG_PHEN,
    link: `${path.PRODUCTS}?categoryID=3`,
  },
  {
    id: 4,
    name: 'Hộp quà tặng',
    image: images.IMG_PRODUCT_HOP_QUA_TANG,
    link: `${path.PRODUCTS}?categoryID=4`,
  },
];

export const dataBenefits = [
  {
    id: '1',
    title: 'Đối với phụ nữ sau sinh',
    image: images.IMG_FOR_WOMAN_AFTER_BIRTH,
    benefits: [
      {
        id: '1',
        name: 'Phục hồi sau sinh nhanh chóng, đặc biệt khi sinh mổ',
      },
      {
        id: '2',
        name: 'Tăng cường chất và lượng sữa, giúp trẻ khoẻ mạnh',
      },
      {
        id: '3',
        name: 'Tăng khả năng hấp thụ canxi cho mẹ',
      },
      {
        id: '4',
        name: 'Chữa rạn da, mang lại làn da mịn màng',
      },
      {
        id: '5',
        name: 'Bổ sung sắt, tăng sinh hồng cầu thiếu hụt',
      },
      {
        id: '6',
        name: 'Ổn định hệ tiêu hoá còn non yếu của mẹ',
      },
    ],
  },
  {
    id: '2',
    title: 'Đối với trẻ em',
    image: images.IMG_FOR_CHILDREN,
    benefits: [
      {
        id: '7',
        name: 'Nâng cao hệ miễn dịch, kích thích tái tạo tế bào',
      },
      {
        id: '8',
        name: 'Tăng cường hệ miễn dịch, giảm bệnh vặt',
      },
      {
        id: '9',
        name: 'Tăng khả năng hấp thụ canxi, tăng chiều cao',
      },
      {
        id: '10',
        name: 'Tăng cường trí nhớ, trẻ thông minh, lanh lợi',
      },
      {
        id: '11',
        name: 'Bé ngủ ngon, tinh thần thoải mái',
      },
      {
        id: '12',
        name: 'Tăng khả năng hấp thụ dưỡng chất, thải độc',
      },
    ],
  },
  {
    id: '3',
    title: 'Đối với người lớn tuổi',
    image: images.IMG_FOR_OLD_PEOPLE,
    benefits: [
      {
        id: '13',
        name: 'Nâng cao hệ miễn dịch, kích thích tái tạo tế bào',
      },
      {
        id: '14',
        name: 'Kích thích vị giác, tiêu hoá, giảm táo bón',
      },
      {
        id: '15',
        name: 'Thải độc, ổn định huyết áp, tim mạch, đường huyết',
      },
      {
        id: '16',
        name: 'Cải thiện hệ hô hấp, hệ thần kinh',
      },
      {
        id: '17',
        name: 'Giảm đau nhức xương khớp',
      },
      {
        id: '18',
        name: 'Phòng ngừa và hỗ trợ điều trị ung thư',
      },
    ],
  },
  {
    id: '4',
    title: 'Đối với mẹ bầu',
    image: images.IMG_FOR_WOMAN_PREGNANT,
    benefits: [
      {
        id: '19',
        name: 'Cung cấp dưỡng chất cho mẹ và thai nhi',
      },
      {
        id: '20',
        name: 'Giảm ốm nghén, chống trầm cảm',
      },
      {
        id: '21',
        name: 'Phục hồi nhanh sau sinh',
      },
      {
        id: '22',
        name: 'Tăng đề kháng cho mẹ và thai nhi',
      },
      {
        id: '23',
        name: 'Dưỡng da, hạn chế rạn da khi mang thai',
      },
      {
        id: '24',
        name: 'Giúp thai nhi phát triển toàn diện',
      },
    ],
  },
  {
    id: '5',
    title: 'Đối với nam giới',
    image: images.IMG_FOR_MAN,
    benefits: [
      {
        id: '25',
        name: 'Cải thiện trí nhớ, giúp tinh thần minh mẫn',
      },
      {
        id: '26',
        name: 'Thanh nhiệt, giải rượu bia, nhanh chóng',
      },
      {
        id: '27',
        name: 'Chống lão hoá, phục hồi chức năng gan thận',
      },
      {
        id: '28',
        name: 'Bổ huyết, thông đàm, giảm thiểu tình trạng viêm khớp',
      },
      {
        id: '29',
        name: 'Giúp cơ bắp săn chắc, đốt cháy mỡ thừa',
      },
      {
        id: '30',
        name: 'Tăng cường chức năng sinh lý cho phái mạnh',
      },
    ],
  },
];
