import { styled } from '@mui/system';
import variableStyles from 'assets/styles/variable-styles';
import { rgba, pxToRem } from 'utils/theme';

export const Button = styled('button')`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: ${pxToRem(16)};
  right: ${pxToRem(16)};
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  background: transparent;
  z-index: 9;
  svg {
    margin-top: ${pxToRem(6)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      stroke: ${rgba(variableStyles.secondaryColor)};
    }
  }
`;
