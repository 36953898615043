export function formatNumber(num) {
  if (num || num === 0) {
    try {
      return num.toLocaleString('en-US', {
        currency: 'VND',
      });
    } catch (error) {
      return '';
    }
  }
  return '';
}

export function numberWithCommas(num) {
  let x = 0;
  if (num) {
    x = formatNumber(num);
    return x.replace(/,/g, '.');
  }
  return x;
}

export function kFormatter(num) {
  if (Math.abs(num) > 999) {
    return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`;
  }
  return `${Math.sign(num) * Math.abs(num)}`;
}
