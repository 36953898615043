import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// appPage
import App from 'pages/App';
// global-styles
import AppGlobalStyles from 'assets/styles/global-styles';
// styles
import 'assets/styles/global-fonts.css';
import 'assets/styles/global-icons.css';
import 'assets/styles/react-slick.scss';

const MOUNT_NODE = document.getElementById('app');

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 660,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
      <AppGlobalStyles />
    </ThemeProvider>
  </React.StrictMode>,
  MOUNT_NODE,
);
