import { makeStyles } from '@mui/styles';
import variableStyles from 'assets/styles/variable-styles';
import images from 'constants/images';
import { rgba, pxToRem } from 'utils/theme';

export const useStyles = makeStyles((theme) => ({
  blockTitle: {
    textAlign: 'center',
    paddingBottom: pxToRem(32),
  },
  title: {
    position: 'relative',
    fontFamily: 'iCiel Nabila',
    color: rgba(variableStyles.primaryColor),
    fontStyle: variableStyles.fsNormal,
    fontWeight: variableStyles.fwNormal,
    fontSize: variableStyles.fontSize_32,
    lineHeight: variableStyles.lineHeight_36,
    letterSpacing: variableStyles.letter_spacing_4,
    '&:before': {
      content: '""',
      width: pxToRem(36),
      height: pxToRem(36),
      position: 'absolute',
      top: 0,
      left: pxToRem(-40),
      backgroundImage: `url(${images.ICON_BIRDS})`,
      backgroundSize: 'cover',
    },

    '&:after': {
      content: '""',
      width: pxToRem(40),
      height: pxToRem(40),
      position: 'absolute',
      top: 0,
      right: pxToRem(-46),
      backgroundImage: `url(${images.ICON_BIRDS})`,
      backgroundSize: 'cover',
      transform: 'rotateY(180deg)',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: variableStyles.fontSize_28,
      lineHeight: variableStyles.lineHeight_32,
    },
  },
}));
