import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// styles
import variableStyles from 'assets/styles/variable-styles';
import { Button } from './style';

function Path(props) {
  return (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      strokeLinecap="round"
      {...props}
    />
  );
}

function MenuToggle({ toggle }) {
  return (
    <Button type="button" onClick={toggle}>
      <svg height="32" width="32" viewBox="0 0 32 32">
        <Path
          variants={{
            closed: { d: 'M 2 2 L 30 2' },
            open: { d: 'M 3 21 L 24 2' },
          }}
        />
        <Path
          d="M 2 10 L 30 10"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 18 L 30 18' },
            open: { d: 'M 3 2 L 24 21' },
          }}
        />
      </svg>
    </Button>
  );
}

MenuToggle.propTypes = {
  toggle: PropTypes.func,
};

export default MenuToggle;
