import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import variableStyles from 'assets/styles/variable-styles';
import { rgba, pxToRem } from 'utils/theme';

export const useStyles = makeStyles((theme) => ({
  headerWrapper: {},
  appBar: {
    background: rgba(variableStyles.primaryColor),

    '&:focus-visible': {
      outline: 'none',
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: pxToRem(8),
      '& .mui-image-wrapper': {
        width: `${pxToRem(200)} !important`,
        height: `${pxToRem(58)} !important`,
      },
    },
  },
  sidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    background: rgba(variableStyles.primaryColor, 0.95),
    zIndex: 3,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '8px 16px',
      '& .mui-image-wrapper': {
        width: 'auto !important',
        height: `${pxToRem(68)} !important`,
      },
    },
  },
  toolbarAnchor: {
    [theme.breakpoints.up('md')]: {
      minHeight: pxToRem(84),
    },
    [theme.breakpoints.down('md')]: {
      minHeight: pxToRem(74),
    },
  },
}));

export const Logo = styled('div')`
  cursor: pointer;
  z-index: 4;
`;
