import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, CardContent, Stack, Typography, Box } from '@mui/material';
import Image from 'mui-image';
// utils
import { numberWithCommas } from 'utils';
// constants
import { categoryList } from 'constants/dataExample';
// styles
import { useStyles } from './style';

function ProductCard({ product, size }) {
  const classes = useStyles();

  const category = categoryList.find(({ id }) => id === product?.categoryId);

  const moneyDiscount =
    product.price - (product.price * product.discount) / 100;

  const bonusPrice = 50000;

  const renderPrice = () => {
    if (product?.categoryId === 3) {
      return (
        <Stack spacing={0.5}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="body"
              component="span"
              sx={{
                color: '#616E7C',
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '16px',
                  sm: '20px',
                },
              }}
            >
              Mua lẻ:
            </Typography>
            <span
              className={classNames('product-price', {
                'product-price__nodiscount': !product.discount,
                'text-line-through title-1': product.discount,
              })}
            >
              {`${numberWithCommas(product.price)}đ`} / hủ
            </span>

            <span className="product-discount">
              {product.discount && `${numberWithCommas(moneyDiscount)}đ`}
            </span>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="body"
              component="span"
              sx={{
                color: '#616E7C',
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '16px',
                  sm: '20px',
                },
              }}
            >
              Tiết kiệm:
            </Typography>
            <span
              className={classNames('product-price', {
                'product-price__nodiscount': !product.discount,
                'text-line-through title-1': product.discount,
              })}
            >
              Mua 10 tặng 1
            </span>
          </Stack>
        </Stack>
      );
    }
    return (
      <Stack spacing={0}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Typography
            variant="body"
            component="span"
            sx={{
              color: '#616E7C',
              fontSize: {
                xs: '12px',
                sm: '14px',
              },
              lineHeight: {
                xs: '16px',
                sm: '20px',
              },
            }}
          >
            100gram:
          </Typography>
          <span
            className={classNames('product-price', {
              'product-price__nodiscount': !product.discount,
              'text-line-through title-1': product.discount,
            })}
          >
            {`${numberWithCommas(product.price)}đ`}
          </span>
          <span className="product-discount">
            {product.discount && `${numberWithCommas(moneyDiscount)}đ`}
          </span>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Typography
            variant="body"
            component="span"
            sx={{
              color: '#616E7C',
              fontSize: {
                xs: '12px',
                sm: '14px',
              },
              lineHeight: {
                xs: '16px',
                sm: '20px',
              },
            }}
          >
            50gram:
          </Typography>
          <span
            className={classNames('product-price', {
              'product-price__nodiscount': !product.discount,
              'text-line-through title-1': product.discount,
            })}
          >
            {`${numberWithCommas(product.price / 2 + bonusPrice)}đ`}
          </span>
          <span className="product-discount">
            {product.discount && `${numberWithCommas(moneyDiscount)}đ`}
          </span>
        </Stack>
      </Stack>
    );
  };

  return (
    <Card className={classes.card}>
      <Box>
        <Image src={product?.image} alt={product?.name} duration={500} />
      </Box>
      <CardContent className={classes.content}>
        <div className="product-category">
          <div className="product-category__name">{category.name}</div>
        </div>
        <div className="product-name">{product?.name}</div>
        {renderPrice()}
      </CardContent>
    </Card>
  );
}

ProductCard.propTypes = {
  size: PropTypes.string,
  product: PropTypes.object,
};
ProductCard.defaultProps = {
  product: {
    price: 0,
    discount: 0,
  },
};

export default ProductCard;
