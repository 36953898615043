import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
// styles
import { useStyles } from './style';

function CustomButton(props) {
  // props
  const { color, className, ...other } = props;

  const classes = useStyles(props);

  return (
    <Button
      className={classNames(classes.root, {
        [className]: className,
      })}
      {...other}
    />
  );
}

CustomButton.propTypes = {
  color: PropTypes.oneOf(['blue', 'red']).isRequired,
  className: PropTypes.string,
};

export default CustomButton;
