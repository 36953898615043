import { makeStyles } from '@mui/styles';
import variableStyles from 'assets/styles/variable-styles';
import { rgba, pxToRem } from 'utils/theme';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: pxToRem(12),
    border: `1px solid ${variableStyles.primaryColor}`,
    boxShadow:
      'rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem',

    '& .mui-image-wrapper': {
      overflow: 'hidden' /* Removing this will break the effects */,
      borderRadius: `${pxToRem(6)} ${pxToRem(6)} 0 0`,
      [theme.breakpoints.up('md')]: {
        '& .mui-image-img': {
          transformOrigin: '50% 65%',
          transitionProperty: 'initial !important',
          transitionDuration: '500ms !important',
          transition:
            'filter .6s, opacity .6s, transform .6s, box-shadow .3s, -webkit-filter .6s, -webkit-transform .6s, -webkit-box-shadow .3s',
        },
        '&:hover': {
          '& .mui-image-img': {
            transform: 'scale(1.1)',
          },
        },
      },
      '& .mui-image-img': {
        aspectRatio: '1 / 1',
        borderRadius: `${pxToRem(6)} ${pxToRem(6)} 0 0`,
      },
    },
  },
  content: {
    fontWeight: variableStyles.fwBold,
    [theme.breakpoints.up('xs')]: {
      padding: pxToRem(10),
      '&:last-child': {
        paddingBottom: pxToRem(10),
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: pxToRem(16),
      '&:last-child': {
        paddingBottom: pxToRem(16),
      },
    },

    '& .product-category': {
      marginBottom: pxToRem(4),
      '& .product-category__name': {
        color: rgba(variableStyles.neutral500),

        [theme.breakpoints.up('sm')]: {
          fontSize: variableStyles.fontSize_14,
          lineHeight: variableStyles.lineHeight_20,
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: variableStyles.fontSize_12,
          lineHeight: variableStyles.lineHeight_16,
        },
      },
    },

    '& .product-name': {
      color: rgba(variableStyles.primaryInk),
      marginBottom: pxToRem(8),

      [theme.breakpoints.up('md')]: {
        fontSize: variableStyles.fontSize_18,
        lineHeight: variableStyles.lineHeight_24,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: variableStyles.fontSize_16,
        lineHeight: variableStyles.lineHeight_22,
      },
    },

    '& .product-price': {
      color: rgba(variableStyles.primaryColor),

      '&.product-price__nodiscount': {
        marginLeft: pxToRem(4),
        fontWeight: variableStyles.fwStrongBold,
        [theme.breakpoints.up('md')]: {
          fontSize: variableStyles.fontSize_18,
          lineHeight: variableStyles.lineHeight_24,
        },
        [theme.breakpoints.between('sm', 'md')]: {
          fontSize: variableStyles.fontSize_16,
          lineHeight: variableStyles.lineHeight_22,
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: variableStyles.fontSize_14,
          lineHeight: variableStyles.lineHeight_20,
        },
      },

      '&.text-line-through': {
        textDecoration: 'line-through',
      },
      '&.title-1': {
        color: rgba(variableStyles.neutral500),
        [theme.breakpoints.down('sm')]: {
          fontSize: variableStyles.fontSize_14,
          lineHeight: variableStyles.lineHeight_20,
        },
      },
    },

    '& .product-discount': {
      color: rgba(variableStyles.primaryColor),
      fontWeight: variableStyles.fwStrongBold,

      [theme.breakpoints.up('md')]: {
        fontSize: variableStyles.fontSize_18,
        lineHeight: variableStyles.lineHeight_24,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: variableStyles.fontSize_16,
        lineHeight: variableStyles.lineHeight_22,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: variableStyles.fontSize_14,
        lineHeight: variableStyles.lineHeight_20,
      },
    },
  },
}));
