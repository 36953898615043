// import path from 'constants/path';

const headerRoutes = {
  private: [
    {
      key: 'home',
      name: 'Trang chủ',
    },
    {
      key: 'about-section',
      name: 'Về chúng tôi',
    },
    {
      key: 'product-section',
      name: 'Sản phẩm',
    },
    {
      key: 'benefit-section',
      name: 'Lợi ích yến sào',
    },
    {
      key: 'contact-section',
      name: 'Liên hệ',
    },
  ],
};

export default headerRoutes;
