import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// hooks
import { useAppStore } from 'hooks';
// routes
import appRoutes from 'routes';

function App() {
  // store
  const [appStore] = useAppStore();

  function renderComponent() {
    if (appStore.isLogin) {
      return appRoutes.private.map((route) => (
        <Route
          key={route.viewId}
          path={route.path}
          element={<route.element />}
        />
      ));
    }
    return appRoutes.public.map((route) => (
      <Route key={route.viewId} path={route.path} element={<route.element />} />
    ));
  }

  return (
    // eslint-disable-next-line react/jsx-fragments
    <BrowserRouter>
      <Routes>{renderComponent()}</Routes>
    </BrowserRouter>
  );
}

export default App;
